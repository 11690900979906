import React, { useState, useEffect } from 'react';
import './SplitScreenCard.css';
import logo1 from '../img/logo1.png'; // Imagen fija grande

const SplitScreenCard = () => {
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch al archivo PHP para obtener los productos
    fetch('https://gusitostore.cl/getProducto.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la solicitud');
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Hubo un error al cargar los productos');
        setLoading(false);
      });
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handleViewProduct = (id) => {
    window.location.href = `/product/${id}`;
  };

  if (loading) {
    return <p>Cargando productos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="split-screen-unique">
      <div className="split-left-unique">
        <img src={logo1} alt="Logo Fijo" className="left-image-unique" />
      </div>
      <div className="split-right-unique">
        {items.length > 0 && (
          <>
            <div className="card-image-container-unique">
              <img
                src={items[currentIndex].imagen}
                alt={items[currentIndex].nombre}
                className="card-image-unique"
              />
            </div>
            <div className="item-info-card-unique">
              <h3 className="item-name-card-unique">{items[currentIndex].nombre}</h3>
              <p className="item-price-card-unique">
                {parseInt(items[currentIndex].precio).toLocaleString('es-CL', {
                  style: 'currency',
                  currency: 'CLP',
                })}
              </p>
              <div className="item-info-buttons-unique">
                <button
                  className="view-product-button-unique"
                  onClick={() => handleViewProduct(items[currentIndex].id)}
                >
                  Ver producto
                </button>
                <div className="carousel-buttons-unique">
                  <button className="carousel-button-unique right" onClick={handleNext}>
                    ›
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SplitScreenCard;
