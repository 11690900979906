import React, { useState, useEffect, useRef } from "react";
import "./ImageGallerySix.css";
import Slider from "react-slick"; // Importamos el slider de 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ImageGallerySix = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const sliderRef = useRef(null); // Usamos useRef para definir sliderRef

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };
    window.addEventListener("resize", handleResize);
    
    fetch("https://gusitostore.cl/getImagesFromGallerySix.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Hubo un problema al cargar las imágenes.");
        setLoading(false);
      });
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Función para pasar al siguiente item
  const handleNext = () => {
    sliderRef.current.slickNext(); // Acceder al método de Slider usando useRef
  };

  // Función para regresar al item anterior
  const handlePrev = () => {
    sliderRef.current.slickPrev(); // Acceder al método de Slider usando useRef
  };

  if (loading) {
    return <p>Cargando imágenes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const currentItems = [
    items[currentIndex],
    items[(currentIndex + 1) % items.length],
    items[(currentIndex + 2) % items.length],
    items[(currentIndex + 3) % items.length],
    items[(currentIndex + 4) % items.length],
    items[(currentIndex + 5) % items.length],
  ];

  return (
    <div className="image-gallery-container">
      <h2 className="gallery-title">NUESTROS INDISPENSABLES :)</h2>

      {/* Botón para mover a la izquierda */}
      <button className="carousel-button left" onClick={handlePrev}>
        ‹
      </button>

      <div className="image-gallery-wrapper">
        {isMobile ? (
          currentItems.map((item, index) => (
            <div
              key={index}
              className="image-container-six"
              onClick={() => (window.location.href = `/product/${item.id}`)}
            >
              <img
                src={item.urlbase}
                alt={item.nombre}
                className="gallery-image-six"
              />
              <img
                src={item.urlhover}
                alt={`${item.nombre} Hover`}
                className="hover-image-six"
              />
            </div>
          ))
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {items.map((item, index) => (
              <div
                key={index}
                className="image-container-six"
                onClick={() => (window.location.href = `/product/${item.id}`)}
              >
                <img
                  src={item.urlbase}
                  alt={item.nombre}
                  className="gallery-image-six"
                />
                <img
                  src={item.urlhover}
                  alt={`${item.nombre} Hover`}
                  className="hover-image-six"
                />
              </div>
            ))}
          </Slider>
        )}
      </div>

      {/* Botón para mover a la derecha */}
      <button className="carousel-button right" onClick={handleNext}>
        ›
      </button>
    </div>
  );
};

export default ImageGallerySix;
