import React, { useState, useEffect } from 'react';
import './ImageUpload.css'; // Asegúrate de importar el archivo CSS

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [images, setImages] = useState({ desktop: null, mobile: null });
  const [uploadTarget, setUploadTarget] = useState(null); // Controla cuál ID se está actualizando (1 o 2)

  // Obtener las imágenes actuales de portada
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://gusitostore.cl/getPortadaImages.php');
        const data = await response.json();
        setImages({
          desktop: data.desktop || null,
          mobile: data.mobile || null,
        });
      } catch (error) {
        console.error('Error al obtener las imágenes:', error);
      }
    };

    fetchImages();
  }, []);

  // Manejar el cambio de archivo
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Enviar el formulario y subir la imagen
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setUploadStatus('Por favor selecciona una imagen.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', uploadTarget); // Enviar el ID correcto (1 para escritorio, 2 para móvil)

    try {
      const response = await fetch('https://gusitostore.cl/subirPortada.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        setUploadStatus('Imagen subida correctamente.');
        setImages((prevImages) => ({
          ...prevImages,
          [uploadTarget === 1 ? 'desktop' : 'mobile']: result.newImageUrl,
        }));
      } else {
        setUploadStatus(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      setUploadStatus('Error al subir la imagen.');
    }

    setSelectedFile(null);
    setUploadTarget(null); // Reiniciar el objetivo de subida
  };

  return (
    <div className="image-upload-manager-container">
      <h2>Gestionar Portadas</h2>

      {/* Portada de Escritorio */}
      <div className="image-upload-manager-section">
        <h3>Portada Escritorio</h3>
        <div className="image-upload-manager-image-container">
          {images.desktop ? (
            <img src={images.desktop} alt="Portada Escritorio" />
          ) : (
            <p>No hay imagen de portada de escritorio.</p>
          )}
        </div>
        <div className="image-upload-manager-button-container">
          {images.desktop ? (
            <button className="image-upload-manager-change-image-button" onClick={() => setUploadTarget(1)}>Cambiar imagen</button>
          ) : (
            <button className="image-upload-manager-change-image-button" onClick={() => setUploadTarget(1)}>Subir imagen</button>
          )}
        </div>
      </div>

      {/* Portada Móvil */}
      <div className="image-upload-manager-section">
        <h3>Portada Móvil</h3>
        <div className="image-upload-manager-image-container">
          {images.mobile ? (
            <img src={images.mobile} alt="Portada Móvil" />
          ) : (
            <p>No hay imagen de portada móvil.</p>
          )}
        </div>
        <div className="image-upload-manager-button-container">
          {images.mobile ? (
            <button className="image-upload-manager-change-image-button" onClick={() => setUploadTarget(2)}>Cambiar imagen</button>
          ) : (
            <button className="image-upload-manager-change-image-button" onClick={() => setUploadTarget(2)}>Subir imagen</button>
          )}
        </div>
      </div>

      {/* Módulo para subir imagen cuando el botón es clickeado */}
      {uploadTarget && (
        <div className="image-upload-manager-form-section">
          <h3>Subir nueva imagen para {uploadTarget === 1 ? 'Portada Escritorio' : 'Portada Móvil'}</h3>
          <form onSubmit={handleSubmit}>
            <input type="file" className="image-upload-manager-input" onChange={handleFileChange} />
            <button type="submit" className="image-upload-manager-submit-button">Subir Imagen</button>
          </form>
        </div>
      )}

      {/* Mostrar mensaje de estado de la subida */}
      <p className={`image-upload-manager-upload-status ${uploadStatus.includes('Error') ? 'error' : 'success'}`}>{uploadStatus}</p>
    </div>
  );
};

export default ImageUpload;
