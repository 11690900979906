import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext'; 
import './Profile.css'; // Asegúrate de tener los estilos necesarios

const Profile = () => {
  const navigate = useNavigate(); 
  const { logoutUser } = useContext(UserContext); 

  const goToAddProductPage = () => {
    navigate('/add-product'); 
  };

  const goToInventoryPage = () => {
    navigate('/inventory'); 
  };

  const goToTallasPage = () => {
    navigate('/tallas'); // Redirigir a la página de tallas
  };

  const goToModifyCoverPage = () => {
    navigate('/modify-cover'); // Redirigir a la página para modificar portada
  };

  const goToVentasPage = () => {
    navigate('/ventas'); // Redirigir a la página para ver las ventas
  };

  const handleLogout = () => {
    logoutUser(); 
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2>Perfil de Administrador</h2>
        <p>Bienvenida, Anto</p>
        
        <button onClick={goToAddProductPage} className="add-item-button">
          Agregar Item
        </button>

        <button onClick={goToInventoryPage} className="view-inventory-button">
          Ver Inventario
        </button>

        <button onClick={goToTallasPage} className="view-tallas-button">
          Ver Tallas
        </button>

        <button onClick={goToModifyCoverPage} className="modify-cover-button">
          Modificar Portada
        </button>

        {/* Botón para ir a la nueva página de ventas */}
        <button onClick={goToVentasPage} className="view-ventas-button">
          Ver Ventas
        </button>

        <button onClick={handleLogout} className="logout-button">
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
};

export default Profile;
