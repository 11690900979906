import React, { useState, useEffect } from 'react';

const ModifyGallery6 = () => {
  const [currentIds, setCurrentIds] = useState([]);  // Almacena las IDs actuales de la galería 6
  const [newIds, setNewIds] = useState([]);          // Almacena las nuevas IDs seleccionadas
  const [allProducts, setAllProducts] = useState([]); // Lista de todos los productos disponibles
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);       // Para mostrar mensaje de éxito
  const [addingProduct, setAddingProduct] = useState(false); // Controla si se está agregando un nuevo producto
  const [selectedProduct, setSelectedProduct] = useState(null); // Producto seleccionado para agregar

  // Cargar las IDs actuales de la galería 6
  useEffect(() => {
    fetch('https://gusitostore.cl/getGallery6Ids.php')
      .then((response) => response.json())
      .then((data) => {
        setCurrentIds(data); // Guardar las IDs actuales
        setNewIds(data);     // Inicialmente, las nuevas IDs son iguales a las actuales
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching gallery 6 IDs:', error);
        setError('Error al cargar las IDs de la galería.');
        setLoading(false);
      });

    // Cargar todos los productos disponibles
    fetch('https://gusitostore.cl/getAllProducts.php')
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data); // Guardar todos los productos disponibles
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError('Error al cargar los productos.');
      });
  }, []);

  // Manejar el cambio de ID en el selector
  const handleIdChange = (index, newId) => {
    const updatedIds = [...newIds];
    updatedIds[index] = newId;
    setNewIds(updatedIds);
  };

  // Enviar las nuevas IDs al backend para actualizar la base de datos
  const handleUpdate = () => {
    fetch('https://gusitostore.cl/updateGallery6Ids.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: newIds }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess('Galería actualizada correctamente.');
        } else {
          setError('Error al actualizar la galería.');
        }
      })
      .catch((error) => {
        console.error('Error updating gallery 6 IDs:', error);
        setError('Hubo un problema al actualizar la galería.');
      });
  };

  // Agregar un producto a la galería
  const handleAddProduct = () => {
    if (selectedProduct) {
      const updatedIds = [...newIds, selectedProduct];
      setNewIds(updatedIds);
      setCurrentIds(updatedIds);
      setAddingProduct(false);
      setSelectedProduct(null);
    }
  };

  if (loading) {
    return <p>Cargando IDs de la galería...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>Modificar Galería 6</h2>

      {success && <p style={{ color: 'green' }}>{success}</p>}

      <table>
        <thead>
          <tr>
            <th>ID Actual</th>
            <th>Nombre</th>
            <th>Cambiar por</th>
          </tr>
        </thead>
        <tbody>
          {currentIds.map((id, index) => {
            const currentProduct = allProducts.find((product) => product.id === id);
            return (
              <tr key={id}>
                <td>{id}</td>
                <td>{currentProduct ? currentProduct.nombre : 'Desconocido'}</td>
                <td>
                  <select
                    value={newIds[index]}
                    onChange={(e) => handleIdChange(index, parseInt(e.target.value))}
                  >
                    {allProducts.map((product) => (
                      <option key={product.id} value={product.id}>
                        {product.id} - {product.nombre}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Mostrar opción de agregar producto si hay menos de 6 productos */}
      {currentIds.length < 6 && (
        <div style={{ marginTop: '20px' }}>
          <button onClick={() => setAddingProduct(true)}>Agregar Producto ({currentIds.length + 1} de 6)</button>
        </div>
      )}

      {/* Mostrar lista de productos disponibles para agregar */}
      {addingProduct && (
        <div>
          <h3>Elegir Producto</h3>
          <div className="product-list">
            {allProducts.map((product) => (
              <div
                key={product.id}
                className={`product-item ${selectedProduct === product.id ? 'selected' : ''}`}
                onClick={() => setSelectedProduct(product.id)}
                style={{
                  border: selectedProduct === product.id ? '2px solid green' : '1px solid gray',
                  padding: '10px',
                  margin: '5px',
                  cursor: 'pointer',
                }}
              >
                <p>{product.nombre}</p>
              </div>
            ))}
          </div>
          <button onClick={handleAddProduct} style={{ marginTop: '10px' }}>
            Insertar Producto
          </button>
        </div>
      )}

      <button onClick={handleUpdate} style={{ marginTop: '20px' }}>
        Actualizar Galería
      </button>
    </div>
  );
};

export default ModifyGallery6;
