import React, { useState, useEffect } from 'react';
import './FullWidthImages.css';
import { useNavigate } from 'react-router-dom';

const FullWidthImages = () => {
  const navigate = useNavigate();
  const [desktopImage, setDesktopImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://gusitostore.cl/getPortadaImages.php'); // Cambia la URL a la ruta correcta de tu API
        const data = await response.json();

        // Asignar las imágenes según su ID en la tabla portada
        if (data.desktop) {
          setDesktopImage(data.desktop);
        }
        if (data.mobile) {
          setMobileImage(data.mobile);
        }
      } catch (error) {
        console.error('Error al obtener las imágenes de portada:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="images-wrapper">
      <div className="full-width-image-container">
        {/* Si las imágenes están disponibles, se muestran, si no, puedes mostrar un texto de carga */}
        {desktopImage ? (
          <img src={desktopImage} alt="Portada Escritorio" className="full-width-image" />
        ) : (
          <p>Cargando imagen de escritorio...</p>
        )}
        {mobileImage ? (
          <img src={mobileImage} alt="Portada Móvil" className="mobile-full-width-image" />
        ) : (
          <p>Cargando imagen móvil...</p>
        )}
        <button className="shop-now-button" onClick={() => navigate('/shop')}>
          Shop Now
        </button>
      </div>
    </div>
  );
};

export default FullWidthImages;
