import React, { useState, useEffect } from 'react';
import './Inventory.css';
import { FaTrash } from 'react-icons/fa'; // Importar el ícono de basurero desde react-icons

const Inventory = () => {
  const [inventory, setInventory] = useState([]); // Estado para almacenar los productos
  const [categories, setCategories] = useState([]); // Estado para almacenar las categorías
  const [loading, setLoading] = useState(true); // Estado para manejar la carga de datos
  const [expandedCategories, setExpandedCategories] = useState([]); // Estado para manejar qué categorías están expandidas

  // Función para obtener los productos y las categorías de la base de datos
  const fetchInventory = async () => {
    try {
      const response = await fetch('https://gusitostore.cl/fetchFullProducts.php');
      const data = await response.json();
      setInventory(data); // Almacenar los productos en el estado
      setLoading(false); // Terminar la carga
    } catch (error) {
      console.error('Error al obtener el inventario:', error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://gusitostore.cl/getCategories.php');
      const data = await response.json();
      setCategories(data); // Almacenar las categorías en el estado
    } catch (error) {
      console.error('Error al obtener las categorías:', error);
    }
  };

  // Obtener los productos y las categorías al cargar el componente
  useEffect(() => {
    fetchInventory();
    fetchCategories();
  }, []);

  // Función para agrupar los productos por categoría (tipo)
  const groupByCategory = () => {
    return categories.map((category) => ({
      ...category,
      items: inventory.filter((item) => item.tipo === category.id),
    }));
  };

  const groupedInventory = groupByCategory();

  // Función para manejar el clic en una categoría y alternar su expansión
  const handleCategoryClick = (categoryId) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(expandedCategories.filter((id) => id !== categoryId)); // Contraer la categoría si está expandida
    } else {
      setExpandedCategories([...expandedCategories, categoryId]); // Expandir la categoría si está contraída
    }
  };

  // Función para eliminar un producto de la base de datos y del estado local
  const handleDelete = async (productId) => {
    if (!productId) return;

    if (window.confirm("¿Estás seguro de que deseas eliminar este producto?")) {
      try {
        const response = await fetch(`https://gusitostore.cl/deleteProduct.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: productId }),
        });

        const data = await response.json();
        if (data.success) {
          setInventory(inventory.filter((item) => item.id !== productId));
          alert("Producto eliminado correctamente.");
        } else {
          alert("Error al eliminar el producto: " + data.message);
        }
      } catch (error) {
        alert("Hubo un error al intentar eliminar el producto.");
      }
    }
  };

  return (
    <div className="inventory-container">
      <h2>Inventario de Productos</h2>
      {loading ? (
        <p>Cargando inventario...</p>
      ) : (
        <div>
          {groupedInventory.map((category, index) => (
            <div key={category.id} className={`category-section cat-${index + 1}`}>
              {/* Categoría - Mostrar el nombre de la categoría con la cantidad de productos */}
              <h3 onClick={() => handleCategoryClick(category.id)} className="category-title">
                {category.nombre} ({category.items.length}) {/* Mostrar la cantidad de items */}
              </h3>

              {/* Mostrar u ocultar los items de la categoría en forma de tabla */}
              {expandedCategories.includes(category.id) && (
                <table className="inventory-table">
                  <thead>
                    <tr>
                      <th>ID</th> {/* Nueva columna para mostrar el ID */}
                      <th>Nombre</th>
                      <th>Precio</th>
                      <th>Descripción</th>
                      <th>Detalles</th>
                      <th>Imagen</th>
                      <th>Acciones</th> {/* Columna para el ícono de basurero */}
                    </tr>
                  </thead>
                  <tbody>
                    {category.items.length > 0 ? (
                      category.items.map((item) => (
                        <tr key={item.id}> {/* Asegúrate de que 'item.id' sea único */}
                          <td>{item.id}</td> {/* Mostrar el ID */}
                          <td>{item.nombre}</td>
                          <td>${item.precio.toLocaleString('es-CL')}</td>
                          <td>{item.descripcion}</td>
                          <td>{item.detalles}</td>
                          <td>
                            <img
                              src={item.imagen}
                              alt={item.nombre}
                              className="product-image"
                            />
                          </td>
                          <td>
                            <FaTrash
                              onClick={() => handleDelete(item.id)}
                              className="delete-icon"
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No hay productos en esta categoría.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Inventory;
